import { useEffect, useState } from 'react'

function Header() {

    const [opacity, setOpacity] = useState(100)
    const [display, setDisplay] = useState('block')

    let html = document.documentElement
    const startPos = 0
    const endPos = 29
    const frameCount = 272

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    })

    function scrollHandler() {
        const scrollTop = html.scrollTop
        const maxScrollTop = html.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount))  
        setOpacity(1 - (frameIndex - startPos) / 15)
        setDisplay(frameIndex < endPos ? 'block' : 'none')
    }


    return (
        <div className="box" style={{opacity: opacity, display: display}}>
            <h1 style={{
                fontSize: '10em',
                lineHeight: '0.8em',
                letterSpacing: '0.2em',
            }}>
                <span style={{color: '#FEE75C'}}>Tic</span>
                <br />
                <span style={{color: '#5865F2'}}>Tac</span>
                <br />
                <span style={{color: '#ED4245'}}>Toe!</span>
                </h1>         
        </div>
    )
}

export default Header
