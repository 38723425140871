import Navbar from "../components/Navbar"
import LeaderBoradEntry from "../components/LeaderBoradEntry"
import { useEffect, useState } from "react"

function Leaderboard() {

    const [leaderboard, setLeaderboard] = useState([])
    const [refreshDuration, setRefreshDuration] = useState(60)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        fetch('https://api.tictactoe-bot.xyz/leaderboard', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            setLeaderboard(res.leaderboard.sort((a, b) => a.position - b.position))
            console.log(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="leaderboard">
            <Navbar />
            <div className="container">
                <h1>Leaderboard:</h1>
                {leaderboard.map((entry, index) => {
                    return (
                        <LeaderBoradEntry key={index} data={entry} />
                    )
                })}
            </div>
        </div>
    )
}

export default Leaderboard
