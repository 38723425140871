import { useEffect, useState } from 'react'

function Singleplayer() {

    const [opacity, setOpacity] = useState(0)
    const [display, setDisplay] = useState('block')


    let html = document.documentElement
    const startPos = 120
    const endPos = 200
    const frameCount = 272

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    })

    function scrollHandler() {
        const scrollTop = html.scrollTop
        const maxScrollTop = html.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount))  
        setDisplay(frameIndex < endPos + 70 ? 'block' : 'none')
        if (frameIndex < endPos) {
            setOpacity(Math.min(1, (frameIndex - startPos) / 10))
        } else {
            setOpacity(1 - frameIndex/120)
        }
    }


    return (
        <div className="box" style={{opacity: opacity, display: display}}>
            <h2 style={{
                fontSize: '3.2em',
                color: '#5865F2',
            }}>
                Singleplayer:
            </h2>
            <p style={{
                fontSize: '2.5em',
            }}>
                No friends?
                No worries!
                With the new Tic-Tac-Toe Bot you can play all day long without having to worry about any social interactions at all
            </p>  
        </div>
    )
}

export default Singleplayer
