import { AiFillTrophy } from 'react-icons/ai'
import { FaSkullCrossbones } from 'react-icons/fa'
import { BiBot } from 'react-icons/bi'

const LeaderBoradEntry = ({ data }) => {

    const botClientId = '919549400919986176'

    return (
        <div className={`entry pos-${data.position}`}>
            <p className='name'>
                <span className='medal'>{
                    data.position === 0 ? '🏆':
                    data.position === 1 ? '🥈' :
                    data.position === 2 && '🥉' 
                }</span>{
                data.position + 1 + '. ' + data.username}
                {data.id === botClientId && <BiBot className='botIcon' />}
            </p>
            <div className="stats">
                <p className="wins"><AiFillTrophy />{data.wins}</p>
                <p className="loses"><FaSkullCrossbones />{data.loses}</p>
            </div>            
        </div>
    )
}

LeaderBoradEntry.defaultProps = {
    data: {
        username: 'Player',
        wins: 0,
        loses: 0,
        draws: 0,
    }
}


export default LeaderBoradEntry
