
function Footer() {
    return (
        <footer>
            <p className="copy">&copy; 2021 TicTacToe Team</p>
        </footer>
    )
}

export default Footer
