const items = Object.freeze([
    {   
        name: 'Leaderboard',
        link: '/leaderboard',
        external: false,
    },
    {   
        name: 'Docs',
        link: '/docs',
        external: false,
    },
    {
        name: 'Team',
        link: '/team',
        external: false,
    }, 
    {
        name: 'Dashboard',
        link: 'https://dash.tictactoe-bot.xyz',
        external: true
    },
    {
        name: 'Invite',
        link: '/invite',
        external: false,
        class: 'inviteButton',
    }
])

module.exports.Items = items;