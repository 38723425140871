import { Data } from '../utils/LandingOverviewHolder.ts'
import { useEffect, useState } from 'react'

function Overview() {

    const [opacity, setOpacity] = useState(0)

    let html = document.documentElement
    const startPos = 260
    const frameCount = 272

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    })

    function scrollHandler() {
        const scrollTop = html.scrollTop
        const maxScrollTop = html.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount))
        
        setOpacity(Math.min(1, (frameIndex - startPos) / 10))
    }


    return (
        <div className="overwiew" style={{opacity: opacity}}>
            {Data.map((item, index) =>
                <div key={index} className={'item prio-' + item.prio}>
                    <h2 style={{
                        color: item.color || '#5865F2',
                    }}>{item.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: item.text}} style={{
                        fontSize: '1.3em',
                    }}></p>
                </div>
            )}            
        </div>
    )
}

export default Overview
