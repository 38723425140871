import { Items } from '../utils/NavbarItemHolder.ts'
import { Link } from "react-router-dom"
import Navbar from '../components/Navbar'
import { useEffect } from 'react'
import AOS from 'aos'

function Menu() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <div>
            <Navbar menuToggled />
            <div className="container menu">
                {Items.map((item, index) => 
                    !item.external ?
                        <Link key={index} data-aos="flip-down" className={item.class || ''} to={item.link}>{item.name}</Link>
                    :
                        <a key={index} data-aos="flip-down" className={item.class || ''} href={item.link}>{item.name}</a>
                )}
            </div>
        </div>
    )
}

export default Menu
