import { useEffect, useState } from 'react'

function Multiplayer() {

    const [opacity, setOpacity] = useState(0)
    const [display, setDisplay] = useState('block')


    let html = document.documentElement
    const startPos = 30
    const endPos = 50
    const frameCount = 272

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    })

    function scrollHandler() {
        const scrollTop = html.scrollTop
        const maxScrollTop = html.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount))  
        setDisplay(frameIndex < endPos + 70 ? 'block' : 'none')
        if (frameIndex < endPos) {
            setOpacity(Math.min(1, (frameIndex - startPos) / 100))
        } else {
            setOpacity(1 - frameIndex/170)
        }
    }


    return (
        <div className="box" style={{opacity: opacity, display: display}}>
            <h2 style={{
                fontSize: '3.2em',
                color: '#FEE75C',
            }}>
                Multiplayer:
            </h2>
            <p style={{
                fontSize: '2.5em',
            }}>
                With the new multiplayer feature you are now able to compete against other players worldwide, 
                be it your friends family or even strangers! <br />
                Show ’em who’s boss!
            </p>  
        </div>
    )
}

export default Multiplayer
