import Navbar from "../components/Navbar"
import Invite from "../assets/docs/invite.png"
import Perms from "../assets/docs/perms.png"


function Documentation() {
    return (
        <div className='docs'>
            <Navbar />
            <div className="container">
                <h1>Documentation:</h1>
                <h2>Adding the bot to your server</h2>
                <hr />
                <h3>Step 1:</h3>
                <p>
                    Invite the bot using <a href="/invite">this link</a>. 
                    If needed, login to Discord. 
                    Now select the server, you want to add the bot to, from the dropdown menu and click Continue.
                </p>
                <picture>
                    <img src={window.location.origin + '/img/docs/invite.webp'} height="800px" alt="Discord Invite Screen" />
                </picture>
                <h3>Step 2:</h3>
                <p>
                    Next, make sure that all the required permissions are given, like in the picture below.
                </p>
                <picture>
                    <img src={window.location.origin + '/img/docs/perms.webp'} height="900px" alt="Discord Permission Screen" />
                </picture>
                <p>
                    By pressing the Authorize button and completing the Captcha,
                    the bot will be added to the previously selected server
                    <br /><br />
                    If you're having problems, <a href="#">join</a> our official support server.
                </p>
                <h2 style={{marginTop: '50px'}}>Basic Commands</h2>
                <hr />
                <p>TicTacToe uses Slash-Commands, so you don't have to use a prefix!</p>
                <p>Every command starts with a <code>/</code>, followed by one of the commands bellow:</p>
                <ul className="commands">
                    <li><code>/tictactoe</code> - Start a new game of TicTacToe</li>
                    <li><code>/leaderboard</code> - View the worldwide leaderboard of the TicTacToe universe.</li>
                    <li><code>/stats</code> - Look up a particular TicTacToe's player statistics.</li>
                    <li><code>/invite</code> - Get a link to invite TicTacToe to another server.</li>
                    <li><code>/ping</code> - View bot latency.</li>
                    <li><code>/support</code> - Get a link to our official support server.</li>
                    <li><code>/help</code> - Displays a list of all commands.</li>
                </ul>
            </div>
        </div>
    )
}

export default Documentation
