import Header from './LandingTexts/Header.jsx';
import Multiplayer from './LandingTexts/Multiplayer.jsx';
import Singleplayer from './LandingTexts/Singleplayer.jsx';

function TextBox() {
    return (
        <div className="text-box">
            <Header />
            <Multiplayer />
            <Singleplayer />
        </div>
    )
}

export default TextBox
