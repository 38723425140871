const overview = [
    {
        title: 'Multiplayer',
        color: '#FEE75C',
        prio: 1,
        text: 'With the new multiplayer feature you are now able to compete against other players worldwide, be it your friends, family, or even strangers! <br/> Show ’em who’s boss!',
        pconly: false
    },
    {
        title: 'Singleplayer',
        color: '#5865F2',
        prio: 3,
        text: 'No friends? No worries!<br/> With the new Tic-Tac-Toe Bot you can play all day long without having to worry about any social interactions at all',
        pconly: false
    }, 
    {
        title: 'Tournaments',
        color: '#ED4245',
        prio: 3,
        text: 'Host Tournaments!<br/> Bet on the winners!<br/> And most importantly: <br/>Have fun! <br/><br/> <span style="font-size: 1.5rem; color: #75ebbb;">Coming Soon!</span>',
        pconly: false
    }, 
    {
        title: 'Documentation',
        color: '#EB459E',
        prio: 2,
        text: 'Wondering how to invite the bot to your Server? Have a look at our Documentation!<div class="invite-container"><a href="/docs" class="invite">Docs</a></div>',
        pconly: false
    },
    {
        title: 'Invite',
        color: '#4FFD80',
        prio: 1,
        text: 'The best game bot to have on your discord server!<br/> Play now!<br/> <div class="invite-container"><a href="/invite" class="invite">Invite</a></div>',
        pconly: true
    }, 
    {
        title: 'Support',
        color: '#EB459E',
        prio: 2,
        text: 'In case you need any help, feel free to contact us!<br/> We are always available to chat! <div class="invite-container"><a href="https://tictactoe-bot.xyz/support" class="invite">Support</a></div>',
        pconly: false
    }, 
]

module.exports.Data = overview