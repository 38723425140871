import Navbar from "../components/Navbar"
import { Members } from "../utils/TeamMemberHolder.ts"
import TeamMember from "../components/TeamMember"

function Team() {
    return (
        <div className='team'>
            <Navbar />
            <div className="container">
                <h1>Our Team:</h1>
                <div className="members">
                    {Members.map((member, index) =>
                        <TeamMember key={index} {...member} />
                    )} 
                </div>
            </div>
        </div>
    )
}

export default Team
