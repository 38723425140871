import Navbar from "../components/Navbar"
import { Data } from '../utils/LandingOverviewHolder.ts'
import { useEffect } from 'react'
import AOS from 'aos'

function MobileLanding() {

  useEffect(() => {
    AOS.init()
  }, [])

  return <div className='mobileLanding'>
    <Navbar />
    <picture>
      <img className="previewIMG" src={document.location + 'img/mobilelanding.webp'} />
    </picture>
    <div className='container'>
      <div className="titleSection">
        <h2 className="subtitle">Discord Bot</h2>
        <h1 className="title">TicTacToe</h1>
        <a href="/invite" className="invite">Invite</a>
      </div>
    </div>
    <div className="infos">
      {Data.map((item, index) =>
          <div data-aos="fade-left" className="info" key={index} style={{display: item.pconly ? 'none' : 'block'}}>
            <h3 className="title">{item.title}</h3>
            <hr className="seperator" />
            <p className="text" dangerouslySetInnerHTML={{__html: item.text}}></p>
          </div>
      )}
    </div>
  </div>;
}

export default MobileLanding;
