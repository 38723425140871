import { Items } from '../utils/NavbarItemHolder.ts'
import { Link } from 'react-router-dom'
import { HiMenuAlt3 } from 'react-icons/hi'

function Navbar({menuToggled}) {
    return (
        <div className="nav-container">
            <ul className='navbar'>
                <div className="left">
                    <li>
                        <Link to="/" className='img-link'>
                            <picture>
                                <img className="logo" src={window.location.origin + '/img/logo.webp'} alt="TTT Logo" />
                            </picture>
                        </Link>
                    </li>
                </div>
        
                <div className="collapse">
                    {Items.map((item, index) => 
                        !item.external ?
                            <li key={index}><Link className={item.class || ''} to={item.link}>{item.name}</Link></li>
                            :
                            <li key={index}><a className={item.class || ''} href={item.link}>{item.name}</a></li>
                    )}
                </div>

                <div className="burgerMenu">
                    <Link to={menuToggled ? '/' : '/menu'}>
                        <HiMenuAlt3 />
                    </Link>
                </div>
            </ul>
        </div>
    )
}

export default Navbar
