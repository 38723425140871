
function TeamMember(props) {
    return (
        <div className="member">
            <div className="accentColor" style={{backgroundColor: props.color}} />
            <div className="avatar">
                <img src={props.avatar} alt="Avatar" />
            </div>
            <div className="holder">
                <p className="name">{props.name}<span className="tag">#{props.tag}</span></p>
                <hr />
                <h5 className="categoryTitle">Roles</h5>
                <div className="roles">
                    {props.roles.map((role, index) =>
                        <RoleCard key={index} {...role} />
                    )}
                </div>
            </div>
        </div>
    )
}


function RoleCard(props) {
    return (
        <div className="roleCard">
            <div className="dot" style={{backgroundColor: props.color}} />
            <p className="name">{props.name}</p>
        </div>
    )
}

export default TeamMember
