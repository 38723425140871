import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import './styles/main.scss'

import Footer from './components/Footer'
import Landing from './pages/Landing';
import MobileLanding from "./pages/MobileLanding";
import Leaderboard from './pages/Leaderboard';
import Docs from './pages/Documentation';
import Menu from './pages/Menu';
import Invite from './pages/Invite';
import Team from './pages/Team';

function App() {

  const isMobile = window.innerWidth < 768;


  return (
    <>
      <Routes>
        <Route path="/" element={isMobile ? <MobileLanding /> : <Landing />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/team" element={<Team />} />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
