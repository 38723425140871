import Navbar from "../components/Navbar"
import { useEffect, useState } from 'react'
import { D } from "../utils/LandingTextData.ts"
import Overview from "../components/Overview"
import TextBox from "../components/TextBox"

function Landing () {

    let html = null
    let canvas = null
    let ctx = null
    const frameCount = 272
    let framesLeft = 272

    const [scroll, setScroll] = useState(0)
    
    
    const currentFrame = index => (
        `/img/bg/${index.toString().padStart(4, '0')}.png`
    )

    let img = new Image()
    img.onload = () => {
        ctx.drawImage(img, 0, 0)
    }

    useEffect(() => {
        html = document.documentElement
        canvas = document.querySelector('.animation')
        ctx = canvas.getContext('2d')
        canvas.height = 1080;
        canvas.width = 1920;
        img.src = currentFrame(1)
        window.addEventListener('scroll', scrollHandler)

        preloadImages()

        setTimeout(pageScroll, 1000)

    }, [])

    function pageScroll() {
        if (framesLeft <= 2) return
        window.scrollBy(0, 10)
        setTimeout(pageScroll, 1)
    }

    const updateImage = index => {
        img.src = currentFrame(index)
        ctx.drawImage(img, 0, 0)
    }

    function scrollHandler() {
        const scrollTop = html.scrollTop
        const maxScrollTop = html.scrollHeight - window.innerHeight
        const scrollFraction = scrollTop / maxScrollTop
        const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount))

        requestAnimationFrame(() => {
            updateImage(frameIndex + 1)
            setScroll(scrollTop)
            framesLeft = frameCount - frameIndex
        })
    }

    const preloadImages = () => {
        for (let i = 1; i <= frameCount; i++) {
            const img = new Image()
            img.src = currentFrame(i)
        }
    }

    return (
        <div className="landing">
            <Navbar />
            <div className="container">
                <div className="animation-container">
                    <canvas className="animation"></canvas>
                    {D.map((item) =>
                        <p 
                            className="block"
                            key={item.id} 
                            id={item.id}
                            style={{
                                fontSize: item.size || 'normal',
                                fontWeight: item.weight || 'normal',
                                width: item.width || 'auto',
                                height: item.height || 'auto',
                                left: item.left || 'auto',
                                top: item.top || 'auto',
                                opacity: 1 - scroll / item.animation.out
                            }}
                        >
                            {item.text}
                        </p>
                    )}
                    <TextBox />
                </div>
                <Overview />
            </div>   
        </div>
    )
}

export default Landing
