const data = [
    {
        id: '3bdd3536-60ff-11ec-8607-0242ac130002',
        text: 'TicTacToe Discord Bot',
        size: '3rem',
        weight: '900',
        width: '200px',
        left: '10%',
        top: '20%',
        animation: {
            in: 0,
            out: 100,
        }
    }
]

module.exports.D = data;