
const members = Object.freeze([
    {
        name: 'DJCool',
        tag: '9687',
        avatar: 'https://cdn.discordapp.com/avatars/472701425323868161/5be42825f2db4073895b483a370f6c4d.webp?size=1024',
        roles: [
            {
                name: 'Management',
                color: '#0c86ac',
            },
            {
                name: 'Development',
                color: '#c2ce55',
            }
        ],
        color: '#34e8b8',
    },
    {
        name: 'Ferdi',
        tag: '7960',
        avatar: 'https://cdn.discordapp.com/avatars/560114125707935780/450a6f652937f5ef5569d4694a63135c.webp?size=1024',
        roles: [
            {
                name: 'Management',
                color: '#0c86ac',
            },
            {
                name: 'Development',
                color: '#c2ce55',
            }
        ],
        color: '#34e8b8',
    },
    {
        name: 'Iotogen',
        tag: '6032',
        avatar: 'https://cdn.discordapp.com/avatars/261835901909336066/9311a2866ae9019cd0e024fa20da78f5.webp?size=1024',
        roles: [
            {
                name: 'Management',
                color: '#0c86ac',
            }
        ],
        color: '#34e8b8',
    },
    {
        name: 'Timmol',
        tag: '7144',
        avatar: 'https://cdn.discordapp.com/avatars/760511945316106250/50b1e590eafbe05f76b840790ef76a7a.webp?size=1024',
        roles: [
            {
                name: 'Design',
                color: '#ac55ce',
            }
        ],
        color: '#34e8b8',
    },
    {
        name: 'Phil_Ks_',
        tag: '6032',
        avatar: 'https://cdn.discordapp.com/avatars/781572255624069120/073bf57e2cd523507eeae3e9febf3d99.webp?size=1024',
        roles: [
            {
                name: 'Finance',
                color: '#DAFF0B',
            }
        ],
        color: '#34e8b8',
    },
    {
        name: 'NikoKs',
        tag: '7144',
        avatar: 'https://cdn.discordapp.com/avatars/699609714031329391/967b172d647be073941b92a04b7e672a.webp?size=1024',
        roles: [
            {
                name: 'Support',
                color: '#490AF9',
            }
        ],
        color: '#34e8b8',
    }
])

module.exports.Members = members;